
.creation-layout {
    .columns {
        width: 100%;
        tr {
            td {
                background-color: rgba(#000, .2);
                > div {
                    min-height: 40px;
                    min-width: 40px;
                    border:1px dashed rgba(#000, .2);
                    box-sizing: border-box;
                }
            }
        }
    }
}

.widget-ghost {

}
.widget-chosen {
    width: 80px;
    height: 80px;
    position: absolute;
}
.widget-drag {
    width: 80px;
    height: 80px;
}