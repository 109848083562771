@import "../../Styles/Mixin.scss";

.send-message {
    width: 100%;
    height: 40px;
    border-top: 1px solid rgba(#000, .1);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    transition: all .2s;
    &.inactive::after {
        width: 100%;
        height: 40px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        content: 'Chat encerrado.';
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }
    * {
        transition: all .2s;
    }
    &.inactive {
        * {
            filter: blur(10px);
        }
    }
    .preview-send {
        z-index: 99;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0px;
        background-color: #ededed;
        animation: fadeInUp .5s;
        .head {
            width: 100%;
            height: 59px;
            background-color: $color_blue;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            box-sizing: border-box;
            button {
                margin-right: 10px;
                padding: 0px;
                margin: 0px;
                display: flex;
                background-color: transparent;
                margin-right: 10px;
                cursor: pointer;
            }
        }
        .preview {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex: 1;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            img, video, audio {
                max-width: 50%;
                max-height: 40%;
            }
            span {
                font-size: 12px;
                margin-top: 10px;
            }
            button {
                margin-top: 30px;
            }
        }
    }
    .media-upload {
        display: flex;
        align-items: center;
        background: #FFF;
        button {
            height: 100%;
            width: 40px;
            cursor: pointer;
            svg {
                width: 40px;
                height: 20px;
            }
        }
        .submenu {
            position: absolute;
            bottom: 0px;
            right: 0px;
            background: #FFF;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            margin-bottom: 25px;
            margin-right: 5px;
            box-sizing: border-box;
            padding: 15px;
            border-radius: 5px;
            box-shadow: 0px 10px 10px rgba(#000, .1);
            opacity: 0;
            visibility: hidden;
            transition: all .2s;
            &.active {
                opacity: 1;
                visibility: visible;
                margin-bottom: 35px;
            }
            hr {
                width: 100%;
            }
            label {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                overflow: hidden;
                margin: 4px 0px;
                &:hover {
                    span, svg path {
                        color: #999;
                        fill: #999;
                    }
                }
                svg {
                    margin-right: 10px;
                    color: #666;
                }
                span {
                    font-size: 13px;
                    color: #333;
                    cursor: pointer;
                    &.danger {
                        color: red;
                    }
                }
                input {
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    cursor: pointer;
                    opacity: 0;
                    margin-top: -20px;
                }
            }
        }
    }
    .input {
        display: flex;
        flex: 1;
        input {
            width: 100%;
            height: 100%;
            padding: 10px;
            box-sizing: border-box;
        }
    }
    .absolute-chat {
        width: 100%;
        position: absolute;
        height: 40px;
        .set-my-chat {
            width: 100%;
            height: 100%;
            background: $color_blue;
            color: #FFF;
            cursor: pointer;
            &:hover {
                background-color: $color_blue_purple;
            }
        }
    }
}