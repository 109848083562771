@import "../../Styles/Mixin.scss";

#chat-web-t2u {
    width: 100vw;
    height: 100vh;
    background-color: rgba(#000, .04);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .first-access {
        .row-input {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding: 20px;
            label.input-default-zig {
                flex: 1;
                margin: 0px 0px 5px 0px;
                span {
                    font-size: 13px;
                    font-weight: 500;
                }
                input {
                }
                textarea {

                }
            }
            button {
                margin-top: 10px;
            }
        }
    }
}