.chatbots-charts {
    display: flex;
    flex-wrap: wrap;
    .chart-list {
        flex: 2 0 21%; /* explanation below */
        margin: 5px;
        background: rgba(#000, .05);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
            font-weight: 600;
            color: #555;
        }
        g > g > g > text {
            fill: black;
        }
    }
}