@import '../../Styles/Mixin.scss';

#contatos {
    .filter-page {
        display: flex;
        align-items: center;
    }
    .editListNoModal {
        .button {
            margin-top: 20px;
            display: flex;
            background: rgba(#000, 0.04);
            border-radius: 5px;
            overflow: hidden;
            button {
                background: transparent;
                color: rgba($color: #000000, $alpha: 0.5);
                border-radius: 0px;
                border-bottom: 2px solid transparent;
                &.secondary {
                    border-bottom: 2px solid $color_yellow;
                }
            }
        }
        .tfoot {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    .process-load {
        margin-top: 20px;
    }
    .infos-report {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding: 20px;
        background-color: rgba(#000, 0.05);
        border-radius: 5px;
        h4 {
            font-size: 15px;
            display: flex;
            align-items: center;
            margin: 0px 0px 10px 0px;
            line-height: 10px;
            padding: 0px;
            svg {
                margin-right: 5px;
            }
        }
        p {
            font-size: 13px;
            color: #666;
            margin: 0px;
        }
    }
}
