@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import './Mixin.scss';

body, html, a, ul, input, textarea, select, button, fieldset {
    font-family: 'Titillium Web', sans-serif;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
}
body {
}
#zigzag {
    width:95%;
    padding:25px 0px 0px 0px;
    box-sizing: border-box;
    max-width: 100%;
    margin:0px auto;
    animation-name: fadeIn;
    animation-duration: 1s;
}
#tropa {
    &.blur {
        header, .nav-left {
            filter: blur(10px);
        }
    }
}
.react-notification-root  {
    z-index: 9999999999999999;
}
.accordion {
    .item {
        border: 1px solid #e6ecf0;
        display: flex;
        flex-direction: column;
        color: #606a75;
        margin-bottom: 15px;
        &.active {
            .infos {
                display: flex;
            }
        }
        &.down {
            .infos {
                animation-name: slideInDown;
                animation-duration: .5s;
            }
        }
        &.up {
            .infos {
                animation-name: fadeIn;
                animation-duration: .5s;
            }
        }
        .title {
            padding: 15px 20px;
            font-size: 16px;
            background-color: #FFF;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            button {
                background-color: transparent;
                font-size: 20px;
                font-weight: bold;
            }
        }
        .infos {
            display: none;
            background-color: #e6ecf0;
            padding: 20px;
            > div {
                display: flex;
                flex-direction: column;
                margin-right: 40px;
                b {
                    font-size: 16px;
                    font-weight: 500;
                }
                span {
                    font-size: 12px;
                    i {
                        margin-right: 5px;
                    }
                    &.link {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}