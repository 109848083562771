.tooltip {
    svg {
        margin-right: 5px;
        width: 15px;
    }
    .textTooltip {
        position: absolute;
        background: rgba(#000,.9);
        margin-left: 25px;
        padding: 10px 15px;
        left: -13px;
        margin-top: 15px;
        max-width: 93%;
        box-sizing: border-box;
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
        transition: all .2s;
        &:before {
            content: '';
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid rgba(#000,.9);
            position: absolute;
            top: -5px;
            left: 10px;
            display: block;
        }
        p {
            margin: 5px 0px;
            font-size: 12px;
            color: #FFF !important;
        }
    }
    &:hover {
        .textTooltip {
            margin-top: 0px;
            opacity: 1;
            visibility: visible;
        }
    }
}