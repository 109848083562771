@import '../../Styles/Mixin.scss';

.button-dash-icon {
    display: flex;
    padding: 23px 15px;
    background-color: #FFF;
    box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.15);
    color: $color_text;
    box-sizing: border-box;
    align-items: center;
    .icon {
        width: 44px;
        height: 44px;
        border-radius: 100px;
        background-color: $color_blue_baby;
        display: flex;
        justify-content: center;
        align-items: center;
        img {

        }
    }
    .text {
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        b {
            font-size: 18px;
            white-space: nowrap;
        }
        i {
            font-size: 22px;
            font-style: normal;
            margin-top: -10px;
            white-space: nowrap;
        }
    }
    &:hover {
        position: relative;
    }
}
@media(max-width: 1300px){
    .button-dash-icon {
        width: 100% !important;
    }
}