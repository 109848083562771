@import '../../Styles/Mixin.scss';

#novaCampanha {
    .send-camp {
        background-color: $color_blue;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-size: 13px;
        cursor: pointer;
        padding: 10px 15px;
        color: #fff;
        border-radius: 3px;
    }

    .typesend {
        margin-bottom: 10px;
        overflow: hidden;
        border-radius: 5px;
        display: flex;
        button {
            padding: 20px;
            cursor: pointer;
            &.active {
                color: #fff;
                background-color: $color_blue;
            }
        }
    }

    .shortcode {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start !important;
        text-align: left;
        span {
            border-radius: 4px;
            background-color: $color_text;
            color: #fff;
            padding: 5px 10px;
            cursor: pointer;
            font-size: 11px;
            margin-right: 5px;
            &.valid {
                background-color: $color_blue;
            }
            &.invalid {
                background-color: $color_yellow;
            }
        }
    }

    .steps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .button-zig {
            margin-right: -30px;
            width: 80px;
        }
        .step-bt {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            button {
                color: #fff;
                font-weight: 600;
                flex: 1;
                margin-right: 44px;
                margin: 0px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 5px 10px;
                background-color: #8999a3;
                cursor: text;
                transition: all 0.2s;
                b {
                    font-size: 18px;
                }
                span {
                    font-size: 12px;
                }
                &.active {
                    background-color: $color_blue;
                    animation-name: bounce;
                    animation-duration: 1s;
                    border-radius: 5px;
                }
                &.send {
                    cursor: pointer;
                    background-color: $color_yellow;
                    animation-name: bounce;
                    animation-duration: 1s;
                    border-radius: 5px;
                }
            }
            &:last-child {
                > span {
                    display: none;
                }
            }
            > span {
                width: 24px;
                height: 24px;
                margin: 0px 10px;
                background-image: url(../../Images/icos/icon-right.png);
            }
        }
    }

    .step {
        animation-name: fadeIn;
        animation-duration: 2s;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 0px rgba($color: #000000, $alpha: 0.1);
        padding: 20px 30px;
        box-sizing: border-box;
        margin: 30px 0px;
        display: flex;
        flex-direction: column;
        > h2 {
            font-size: 22px;
            color: #606a75;
            margin: 0px;
            padding: 0px;
        }
        > p {
            font-size: 13px;
            color: #606a75;
            margin: 0px;
            padding: 0px;
        }
        .separator {
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            .input-datetime {
                width: 110px;
                height: 30px;
                border: 2px solid rgba(#000, 0.1);
                padding: 5px 15px;
                border-radius: 3px;
                input {
                    width: 100%;
                    height: 100%;
                }
            }
            .input {
                display: flex;
                flex-direction: column;
                width: 340px;
                padding-right: 40px;
                label {
                    display: flex;
                    flex-direction: row;
                    margin: 5px 0px;
                    span {
                        padding-left: 5px;
                    }
                }
                .lists-options {
                    > button {
                        margin-top: 10px;
                    }
                }
            }
            .list {
                display: flex;
                flex-direction: column;
                padding-left: 40px;
                border-left: 1px solid rgba($color: #000000, $alpha: 0.1);
                width: 100%;
                overflow: auto;
                max-height: 100%;
                > div {
                    display: flex;
                    flex-direction: row;
                    background-color: rgba($color: #000000, $alpha: 0.05);
                    border-radius: 5px;
                    justify-content: space-around;
                    margin: 5px 0px;
                    padding: 10px;
                    align-items: center;
                    animation-name: fadeIn;
                    animation-duration: 0.5s;
                    .contacts {
                        padding: 4px 10px;
                        cursor: auto;
                        border-radius: 3px;
                        font-size: 11px;
                        margin-right: 10px;
                        background-color: rgba($color: #000000, $alpha: 0.05);
                        display: flex;
                        align-items: center;
                        font-weight: 200;
                        &:before {
                            display: inline-block;
                            font: normal normal normal 14px/1 FontAwesome;
                            font-size: inherit;
                            text-rendering: auto;
                            font-size: 14px;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            content: '\f2ba';
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                            box-sizing: border-box;
                            margin-right: 5px;
                        }
                    }
                    label,
                    > div {
                        width: 100%;
                        > input {
                            margin-left: 10px;
                            cursor: pointer;
                        }
                        > span,
                        .button-edit-list {
                            font-weight: 600;
                            color: $color_text;
                            cursor: pointer;
                            margin-left: 10px;
                        }
                        .delete {
                            background-color: rgba($color: red, $alpha: 0.3);
                            margin-left: 10px;
                            padding: 3px 10px;
                            border-radius: 4px;
                            cursor: pointer;
                            font-weight: 100;
                            &:hover {
                                background-color: rgba(#000, 0.15);
                            }
                        }
                    }
                }
            }
            .message-campgain {
                align-items: center;
                display: flex;
                flex-direction: column;
                .upload-image {
                    width: 100%;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    .remove-upload {
                        background-color: rgba(red, 0.1);
                        padding: 10px 18px;
                        white-space: nowrap;
                        cursor: pointer;
                        border-radius: 100px;
                    }
                    p {
                        padding: 0px;
                        margin: 0px 0px 10px 0px;
                    }
                    input {
                        margin: 0px;
                    }
                }
                .phone {
                    width: 197px;
                    height: 385px;
                    background-image: url(../../Images/mobile_frame.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    box-sizing: border-box;
                    .display {
                        padding: 10px;
                        box-sizing: border-box;
                        height: 328px;
                        width: 180px;
                        margin: 20px 10px 10px 7px;
                        overflow-x: auto;
                        &.flashSMS {
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            background-image: url(../../Images/campgain/bg-flash.jpg);
                            background-size: cover;
                            .sender {
                                display: none;
                            }
                            .message {
                                background-color: rgba(#fff, 0.2);
                                border-radius: 5px;
                                font-size: 11px;
                                padding: 20px 15px;
                                box-sizing: border-box;
                                color: #fff;
                                text-align: center;
                            }
                        }
                        &.Whatsapp {
                            background-image: url(../../Images/campgain/bg-wpp.jpg);
                            .sender {
                                font-weight: bold;
                                font-size: 13px;
                                color: $color_blue;
                            }
                            .image-upload {
                                width: 100%;
                                margin-top: 10px;
                                border-radius: 5px;
                            }
                            .message {
                                background-color: #fff;
                                border-radius: 5px;
                                font-size: 11px;
                                padding: 10px;
                                box-sizing: border-box;
                                word-break: break-word;
                                white-space: pre-line;

                                > span {
                                    color: $color_blue;
                                }
                            }
                        }
                        &.SMS {
                            .sender {
                                display: none;
                            }
                            background-color: #fff;
                            .message {
                                background-color: rgba(#000, 0.1);
                                border-radius: 5px;
                                font-size: 11px;
                                padding: 10px;
                                box-sizing: border-box;
                                word-break: break-word;
                                white-space: pre-line;
                                > span {
                                    color: $color_blue;
                                }
                            }
                        }
                        &.voice {
                            background: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSTJIPQyc1srMMn2vE3NONbxY18LrOWqyhEA&usqp=CAU')
                                center no-repeat;
                            background-size: cover;
                            > div {
                                display: none;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                background: rgba(#000, 0.5);
                                width: 100%;
                                height: 100%;
                                border-radius: 10px;
                                animation: zoomIn 0.5s;
                                > div {
                                    display: flex;
                                }
                                h6 {
                                    text-align: center;
                                    color: #fff;
                                    font-weight: 400;
                                    padding: 0px 10px;
                                }
                                button {
                                    display: flex;
                                    margin: 0px 10px;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    background: transparent;
                                    border: none;
                                    outline: none;
                                    cursor: pointer;
                                    &:nth-child(1) {
                                        svg {
                                            background: #ff3b2f;
                                        }
                                    }
                                    &:nth-child(2) {
                                        svg {
                                            background: #4bda63;
                                        }
                                    }
                                    svg {
                                        width: 50px;
                                        height: 50px;
                                        border-radius: 100px;
                                        box-sizing: border-box;
                                        padding: 10px;
                                        * {
                                            fill: #fff;
                                        }
                                    }
                                    span {
                                        font-size: 12px;
                                        color: #fff;
                                        font-size: 12px;
                                        margin-top: 10px;
                                    }
                                }
                            }
                            &.active {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                > div {
                                    display: flex !important;
                                }
                            }
                        }
                    }
                }
                .phones {
                    margin-top: 15px;
                    display: inline-block;
                    span {
                        padding: 5px 13px;
                        border-radius: 100px;
                        background-color: rgba(green, 0.3);
                        margin-right: 5px;
                        margin-bottom: 5px;
                        display: inline-block;
                        &.invalid {
                            background-color: rgba(red, 0.2);
                        }
                    }
                }
                > p {
                    font-size: 17px;
                    color: $color_text;
                    width: 100%;
                }
                label {
                    color: $color_text;
                    display: flex;
                    flex-direction: column;
                    margin: 10px 0px;
                    width: 100%;
                    div {
                        display: flex;
                        button {
                            font-size: 16px;
                            background-color: #facc04;
                            border-radius: 100px;
                            padding: 8px 20px;
                            white-space: nowrap;
                            border-radius: 100px;
                            text-transform: uppercase;
                            font-weight: 600;
                            color: $color_text;
                            cursor: pointer;
                            &[type='button'] {
                                background-color: #606a75;
                                color: #fff;
                                margin-left: 10px;
                            }
                        }
                    }
                    b {
                        font-size: 14px;
                        padding-bottom: 5px;
                    }
                    span {
                        font-size: 12px;
                    }
                    input,
                    textarea {
                    }
                    textarea {
                        height: 110px;
                        padding: 10px;
                    }
                }
            }
            .buttons {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 30px;
                button {
                    font-size: 16px;
                    background-color: #facc04;
                    border-radius: 100px;
                    padding: 10px 25px;
                    border-radius: 100px;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: $color_text;
                    cursor: pointer;
                    &[type='button'] {
                        background-color: #606a75;
                        color: #fff;
                        margin-top: 10px;
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

.modal-send-phone {
    position: fixed;
    z-index: 99999;
    background-color: rgba($color: #fff, $alpha: 0.9);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .modal {
        width: 700px;
        text-align: center;
        h2 {
        }
        .send-campgain-type {
            padding: 40px 0px;
            animation-name: fadeIn;
            animation-duration: 1s;
            display: flex;
            justify-content: space-around;
            .phone {
                width: 169px;
                height: 306px;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                align-items: center;
                position: relative;
                padding-top: 60px;
                background-repeat: no-repeat;
                cursor: pointer;
                animation-name: fadeInUp;
                animation-duration: 0.5s;
                &.sms {
                    background-image: url(../../Images/campgain/send-sms.png);
                }
                &.flashsms {
                    background-image: url(../../Images/campgain/send-flashsms.png);
                }
                &.whatsapp {
                    background-image: url(../../Images/campgain/send-whatsapp.png);
                }
                &:hover {
                    button {
                        position: relative;
                        z-index: 10;
                        margin-bottom: 100%;
                    }
                    &:after {
                        background-color: rgba($color: #fff, $alpha: 0.8);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        content: '';
                    }
                }
                button {
                    transition: all 0.5s;
                    padding: 10px 25px;
                    background-color: $color_yellow;
                    border-radius: 100px;
                    color: #606a75;
                    font-weight: 600;
                    cursor: pointer;
                    font-size: 16px;
                    text-transform: uppercase;
                }
            }
        }
    }
}
