#modal {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999999999999;
    top:0;
    left: 0;
    .overlay { 
        background-color: rgba(#000, .8);
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .modal-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        background-color: #FFF;
        border-radius: 9px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        min-width: 650px;
        max-width: 90%;
        > button {
            float: right;
            position: absolute;
        }
        .content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex: 1;
            width: 100%;
            h1 {
                margin-top: 0px;
                padding-top: 0px;
            }
            .container {
                max-height: 80vh;
                overflow: auto;

                .answers-align {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    margin: 0px auto;
                    &.Whatsapp {
                        background-image: url(../../Images/mobile_frame.png);
                        width: 305px;
                        height: 500px;
                        padding: 30px 16px 0px 11px;
                    }
                    &.SMS {
                        background-image: url(../../Images/mobile_frame.png);
                        width: 305px;
                        height: 500px;
                        padding: 30px 16px 0px 11px;
                    }
                    .display {
                        background-color: #EAEAEA;
                        width: 100%;
                        height: 100%;
                        overflow: auto;
                        &.Whatsapp {
                            background-image: url(../../Images/campgain/bg-wpp.jpg);
                        }
                        .answers {
                            display: flex;
                            padding: 10px;
                            flex-direction: column;
                            align-items: flex-start;
                            .answer {
                                background-color: #FFF;
                                padding: 5px 10px;
                                margin-bottom: 10px;
                                border-radius: 8px;
                                border-bottom: 1px solid rgba(#000, .2);
                                span {
                                    font-size: 8px;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}