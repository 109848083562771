@import '../../Styles/Mixin.scss';

.dates-dash-month {
    height: 128px;
    width: 100%;
    float: left;
    margin: -20px 0 0 0;
    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:20px 0px 20px 10px;
        box-sizing: border-box;
        &.transition {
            > div {
                transition: all .5s;
            }
        }
        > div {
            min-width: calc(100% + 2px);
            padding-left: 6px;
            box-sizing: border-box;
            left:0;
        }
    }
    .date-dash-month {
        width: 44px;
        height: 88px;
        border-radius: 100px;
        box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
        text-align: center;
        margin-right: 11px;
        cursor: pointer;
        &:after {
            width: 4px;
            height: 4px;
            border-radius: 100px;
            background-color: $color_blue_white;
            content: '';
            display: inline-block;
            margin-bottom: 7px;
            display: none;
        }
        &.selected {
            &:after {
                display: inline-block;
            }
        }
        .day {
            width: 38px;
            height: 38px;
            border-radius: 100px;
            border: 1px solid $color_blue;
            margin: 3px;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            align-items: center;
            span {
                font-size: 16px;
                color: $color_text;
            }
        }
        .month {
            text-align: center;
            font-size: 14px;
            color: $color_text;
            padding-top: 3px;
        }
        &.active {
            background-image: linear-gradient(to bottom, $color_blue, $color_blue_white);
            .day  {
                background-color: #FFF;
            }
            .month{
                color: #FFF;
            }
            &:after {
                background-color: #FFF;
            }
        }
    }
}