#newTemplateOficial {
    display: flex;
    flex-direction: column;
    > p {
        font-size: 13px;
        color: #333;
    }
    textarea, input {
        padding: 10px;
        margin-bottom: 10px;
    }
    .label-upload {
        margin-bottom: 20px;
        input {
            margin: 0px !important;
            padding-left: 0px !important;
        }
    }
    fieldset {
        margin-bottom: 20px;
        padding: 20px;
        border: 1px dashed rgba(#000, .1);
        border-radius: 5px;
        legend {
            font-size: 13px;
            background-color: #FFF;
        }
        label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            input {
                margin-right: 10px;
            }
            button {
                
                margin-top: -5px;
                cursor: pointer;
            }
        }
    }
}