@import '../../Styles/Mixin.scss';

#chatbot {
    .react-flow__node {
        animation: fadeIn 0.2s linear;
    }
    .chart-chatbot {
        width: 650px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        > div > div > div > div {
            &:nth-child(1) {
                font-size: 8px;
            }
        }
    }
    .flex {
        display: flex;
        width: 100%;
        height: 800px;
        background-color: rgba(#000, 0.03);
        border-radius: 5px;
        border: 1px solid rgba(#000, 0.05);
        position: relative;
        overflow: hidden;
        margin-top: 0px;
    }
    .react-flow {
        position: relative;
    }
    .menu-fixed {
        position: absolute;
        width: 100%;
        height: 62px;
        background-color: #fff;
        border-bottom: 1px solid rgba(#000, 0.1);
        left: 0;
        z-index: 999;
        display: flex;
        align-items: flex-end;
        padding: 0px 0px;
        box-sizing: border-box;
        #embed {
            label {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                span {
                    font-size: 13px;
                    margin-left: 5px;
                }
                input {
                    background: transparent;
                }
            }
            textarea.embed {
                background: #eaeaea;
                padding: 10px;
                height: 80px;
                font-size: 12px;
                border-radius: 5px;
                resize: none;
                margin-top: 20px;
                cursor: pointer;
            }
            .embed-iframe {
                display: flex;
                justify-content: space-between;
                align-items: center;
                iframe {
                    width: 72px;
                    height: 76px;
                    margin-left: 0px;
                    margin-top: 10px;
                    border: none;
                }
            }
            .messages {
                .message {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 4px;
                    background: rgba(#000, 0.05);
                    padding: 5px 10px;
                    margin: 3px 0px;
                    i {
                        font-style: normal;
                        font-size: 12px;
                    }
                    svg {
                        cursor: pointer;
                    }
                }
            }
            .formMessages {
                display: flex;
                justify-content: space-between;
                button {
                    margin-left: 5px;
                    height: 44px;
                    display: flex;
                    padding: 12px 20px;
                }
            }
        }
        .action {
            background: transparent;
            border-bottom: 3px solid #eaeaea;
            padding: 16px 15px;
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
                margin-right: 5px;
                background: rgba(#000, 0.05);
                width: 25px;
                height: 25px;
                padding: 4px;
                box-sizing: border-box;
                border-radius: 100px;
            }
            span {
                color: rgba(#000, 0.5);
            }
            &:hover,
            &.active {
                border-bottom: 3px solid $color_blue_purple;
                background: linear-gradient(to bottom, #fff, rgba(#000, 0.03));
            }
        }
        .submenuModal {
            position: absolute;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            max-width: 300px;
            padding: 20px;
            border-radius: 0px 0px 5px 5px;
            box-shadow: 0px 10px 10px rgba(#000, 0.1);
            opacity: 0;
            margin-top: -40px;
            visibility: hidden;
            transition: all 0.2s;
            .text {
                display: flex;
                align-items: flex-start;
                p {
                    margin-top: 0px;
                    font-size: 13px;
                }
            }
            button {
                cursor: pointer;
            }
            &.active {
                opacity: 1;
                visibility: visible;
                margin-top: 0px;
            }
            .text {
                p {
                    font-size: 12px;
                    color: #555;
                }
            }
            .form {
                display: flex;
                button {
                    padding: 0px 20px;
                    margin-left: 10px;
                    background-color: $color_blue_purple;
                    color: #fff;
                }
            }
            .phones {
                margin-top: 15px;
                display: inline-block;
                span {
                    padding: 5px 13px;
                    border-radius: 100px;
                    background-color: rgba(green, 0.3);
                    margin-right: 5px;
                    font-size: 12px;
                    margin-bottom: 5px;
                    display: inline-block;
                    &.invalid {
                        background-color: rgba(red, 0.2);
                    }
                }
            }
        }
    }
    .edit-dialog {
        position: absolute;
        right: 0;
        height: 100%;
        z-index: 999;
        width: 450px;
        transition: all 0.5s;
        border-left: 1px solid rgba(#000, 0.05);
        background-color: #fff;
        &:after {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            background-image: url('https://web.whatsapp.com/img/bg-chat-tile-light_04fcacde539c58cca6745483d4858c52.png');
            opacity: 0.1;
        }
        &.opened {
            margin-right: 0px;
        }
        &.closed {
            margin-right: -450px;
        }
        .relative {
            position: relative;
            z-index: 3;
            height: 100%;
            display: flex;
            flex-direction: column;
            .head {
                .title {
                    display: flex;
                    justify-content: space-between;
                    background-color: #fff;
                    border-bottom: 1px solid #f2f2f2;
                    padding: 10px 20px;
                    input {
                        background-color: none;
                        flex: 1;
                        height: 40px;
                        padding: 0px;
                        border-bottom: 1px solid rgba(#000, 0.1);
                    }
                    button {
                        padding: 0px 0px;
                        cursor: pointer;
                        width: 40px;
                        text-align: right;
                        background-color: transparent;
                        svg {
                            font-size: 20px;
                        }
                    }
                }
                ul {
                    background-color: #f9f9f9;
                    display: flex;
                    li {
                        padding: 10px 20px;
                        font-size: 13px;
                        cursor: pointer;
                        &.active {
                            border-bottom: 3px solid $color_yellow;
                        }
                    }
                }
            }
            .content {
                height: 100%;
                background-color: rgba(#dddbd1, 0.5);
                overflow: auto;
                > div {
                    display: none;
                }
                &.condicoes {
                    .conditions {
                        display: flex;
                        flex-direction: column;
                    }
                }
                &.conteudo {
                    .messages {
                        display: flex;
                        flex-direction: column;
                    }
                }
                .conditions {
                    padding: 20px;
                    .condition {
                        display: flex;
                        flex-direction: column;
                        background-color: #ecf2f8;
                        border: 1px solid rgba($color: #000000, $alpha: 0.05);
                        padding: 0px;
                        margin-bottom: 20px;
                        border-radius: 5px;
                        position: relative;
                        select,
                        input {
                            width: 100%;
                            height: 44px;
                            border-radius: 4px;
                            padding: 0px 10px;
                            border: 1px solid rgba(#000, 0.1);
                            box-sizing: border-box;
                        }
                        .removeCondition {
                            padding: 20px;
                            display: flex;
                            flex-direction: column;
                        }
                        .if {
                            position: relative;
                            padding: 20px;
                            &.error {
                                background-color: rgba(red, 0.1);
                                &:hover {
                                    background-color: rgba(red, 0.2);
                                }
                                .warning {
                                    display: flex;
                                }
                            }
                            .warning {
                                display: none;
                                position: absolute;
                                left: -15px;
                                width: 30px;
                                height: 30px;
                                background-color: #333;
                                color: $color_yellow;
                                svg {
                                    font-size: 20px;
                                    position: relative;
                                    z-index: 2;
                                    path {
                                        fill: $color_yellow;
                                    }
                                }
                                span {
                                    position: absolute;
                                    white-space: nowrap;
                                    left: 10px;
                                    background-color: #333;
                                    font-size: 12px;
                                    border-radius: 100px;
                                    padding: 4px 10px 4px 15px;
                                    margin-left: -15px;
                                    visibility: hidden;
                                    opacity: 0;
                                    transition: all 0.5s;
                                }
                                &:hover {
                                    span {
                                        opacity: 1;
                                        visibility: visible;
                                        left: 30px;
                                    }
                                }
                                border-radius: 100px;
                                top: calc(50% - 15px);
                                align-items: center;
                                justify-content: center;
                            }
                            .ifCondition {
                                display: flex;
                                justify-content: space-between;
                                select {
                                    &:last-child {
                                        margin-left: 10px;
                                    }
                                }
                            }
                            .target {
                                margin-top: 10px;
                            }
                            .tags {
                                .list {
                                    display: flex;
                                    padding-top: 10px;
                                    flex-wrap: wrap;
                                    .tag {
                                        border-radius: 100px;
                                        background-color: $color_yellow;
                                        color: rgba(#000, 0.5);
                                        padding: 5px 10px;
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;
                                        margin-right: 4px;
                                        margin-bottom: 3px;
                                        svg {
                                            font-size: 12px;
                                            margin-left: 5px;
                                        }
                                        span {
                                            font-size: 11px;
                                        }
                                    }
                                }
                            }
                            &:hover {
                                background-color: rgba(#000, 0.05);
                                .removeAnd {
                                    margin-right: -15px;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            .removeAnd {
                                background-color: red;
                                width: 30px;
                                height: 30px;
                                cursor: pointer;
                                font-size: 20px;
                                border-radius: 100px;
                                color: #fff;
                                position: absolute;
                                right: 0px;
                                top: calc(50% - 15px);
                                transition: all 0.2s;
                                opacity: 0;
                                visibility: hidden;
                            }
                        }
                        .newAnd {
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &:after {
                                width: 100%;
                                height: 1px;
                                background-color: rgba(#000, 0.1);
                                content: '';
                                position: absolute;
                                top: calc(50% - 0.5px);
                            }
                            button {
                                margin: 0px;
                                text-align: center;
                                background-color: #fff;
                                border: 1px solid $color_blue;
                                color: #fff;
                                width: 36px;
                                height: 36px;
                                border-radius: 100px;
                                position: relative;
                                z-index: 2;
                                font-size: 20px;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                &:hover {
                                    background-color: $color_blue;
                                    path {
                                        fill: #fff;
                                    }
                                }
                                svg {
                                    margin: 0px;
                                    path {
                                        fill: $color_blue;
                                    }
                                }
                            }
                        }
                        .targetDialog {
                            padding: 0px 20px;
                            span {
                                padding-bottom: 4px;
                                font-size: 13px;
                            }
                        }
                        .conditionAnd {
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &:after {
                                width: 100%;
                                height: 1px;
                                background-color: rgba(#000, 0.1);
                                content: '';
                                position: absolute;
                                top: calc(50% - 0.5px);
                            }
                            p {
                                margin: 0px;
                                text-align: center;
                                background-color: #fff;
                                width: 25px;
                                height: 25px;
                                border-radius: 100px;
                                position: relative;
                                z-index: 2;
                                font-size: 13px;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                    .exception-target {
                        padding: 20px;
                        background-color: #fff;
                        margin-top: 10px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: left;
                        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.13);
                        &.error {
                            background-color: red;
                        }
                        h5 {
                            margin: 0px;
                            padding: 0px;
                            font-weight: 600;
                        }
                        p {
                            font-size: 13px;
                            margin-top: 0px;
                        }
                        select {
                            width: 100%;
                            height: 44px;
                            border-radius: 4px;
                            padding: 0px 10px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            box-sizing: border-box;
                        }
                    }
                }
                .messages {
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    height: calc(100%);
                    animation: fadeIn 0.5s;
                    .tutorialTypeDialog {
                        padding: 20px;
                        box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
                        background-color: #fff;
                        border-radius: 5px;
                        margin: 20px 20px 0px 20px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        svg {
                            font-size: 30px;
                            min-width: 30px;
                        }
                        div {
                            display: flex;
                            flex-direction: column;
                            margin-left: 20px;
                        }
                        h2 {
                            font-weight: 600;
                            margin: 0px;
                        }
                        p {
                            margin: 0px;
                            font-size: 13px;
                        }
                    }
                    section {
                        display: flex;
                        flex-direction: column;
                        overflow: auto;
                        padding-bottom: 20px;
                        box-sizing: border-box;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 20px;
                        padding-bottom: 117px;
                        flex: 1;
                    }
                    .button-circle {
                        margin-right: 5px;
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                        font-size: 20px;
                        border-radius: 100px;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.removeMessage {
                            background-color: red;
                        }
                        &.editMessage {
                            background-color: $color_blue_purple;
                        }
                        &.newMessage {
                            background-color: $color_blue;
                        }
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                    .messages-bot {
                        > div {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                        }
                        .messageButton {
                            padding: 8px 10px;
                            border-radius: 8px;
                            border: 1px solid rgba(#000, 0.1);
                            margin: 0px 5px;
                            background: rgba(#fff, 0.2);
                            cursor: pointer;
                        }
                    }
                    .messages-user {
                        > div {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                    .message {
                        background-color: #fff;
                        max-width: 60%;
                        padding: 6px 7px 8px 7px;
                        border-radius: 7.5px;
                        box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
                        font-size: 13px;
                        box-sizing: border-box;
                        word-break: break-word;
                        white-space: pre-line;
                        animation: zoomIn 0.5s;
                        &.user {
                            white-space: nowrap !important;
                        }
                        &.bot {
                            background-color: #dcf8c6;
                        }
                        audio,
                        img,
                        video {
                            max-width: 100%;
                            animation: fadeIn 0.5s;
                        }
                    }
                    .config-message {
                        position: relative;
                        padding: 5px;
                        border-radius: 5px;
                        &:hover {
                            background-color: rgba(#000, 0.1);
                        }
                        .variable {
                            width: 100%;
                            box-sizing: border-box;
                            flex-direction: column !important;
                            > label {
                                display: flex;
                                align-items: center;
                                text-align: left;
                                width: 100%;
                                cursor: pointer;
                                span {
                                    font-size: 13px;
                                    padding-left: 5px;
                                }
                            }
                            .form {
                                width: calc(100% + 10px);
                                background-color: rgba(#000, 0.05);
                                position: relative;
                                border-radius: 4px;
                                margin-top: 10px;
                                input {
                                    background-color: transparent;
                                    width: 100%;
                                    padding: 10px;
                                    box-sizing: border-box;
                                    border-radius: 4px;
                                }
                                &:before {
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-bottom: 5px solid rgba(#000, 0.05);
                                    position: absolute;
                                    top: -5px;
                                    left: 10px;
                                    display: block;
                                }
                            }
                        }
                        .buttons {
                            position: absolute;
                            left: 50px;
                            top: calc(50% - 15px);
                            transition: all 0.2s;
                            display: flex;
                            opacity: 0;
                            visibility: hidden;
                        }
                        &:hover {
                            .buttons {
                                opacity: 1;
                                visibility: visible;
                                left: 10px;
                            }
                        }
                    }
                    .config-message-user {
                        padding: 20px;
                        background-color: #fff;
                        margin-top: 10px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.13);
                        cursor: pointer;
                        .text {
                            margin-left: 10px;
                            font-size: 13px;
                        }
                    }
                    .contentnewMessageRelative {
                        position: relative;
                        z-index: 999;
                        .contentNewMessage {
                            display: flex;
                            flex-direction: row !important;
                            justify-content: flex-end;
                            align-items: center !important;
                            margin: 10px 0px;
                            span {
                                margin-right: 5px;
                                font-size: 12px;
                            }
                        }
                        .submenu {
                            &:before {
                                content: '';
                                width: 0;
                                height: 0;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-bottom: 5px solid #fff;
                                position: absolute;
                                top: -5px;
                                right: 15px;
                                display: block;
                            }
                            opacity: 0;
                            visibility: hidden;
                            padding: 10px 0px;
                            border-radius: 5px;
                            box-sizing: border-box;
                            position: absolute;
                            background-color: #fff;
                            top: 40px;
                            margin-top: -10px;
                            box-shadow: 0px 10px 10px rgba(#000, 0.1);
                            transition: all 0.2s;
                            &.active {
                                opacity: 1;
                                visibility: visible;
                                margin-top: 0px;
                            }
                            li {
                                padding: 5px 20px;
                                text-align: right;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                cursor: pointer;
                                &:hover {
                                    span {
                                        font-weight: 600;
                                    }
                                }
                                svg {
                                    margin-left: 9px;
                                }
                                span {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    .editing-message {
                        display: flex;
                        flex-direction: column;
                        background-color: #f0f0f0;
                        width: 100%;
                        position: absolute;
                        bottom: 0px;
                        animation: fadeInUp 0.5s;
                        box-shadow: 8px -10px 10px rgba(#000, 0.05);
                        z-index: 999;
                        .visible {
                            min-height: 62px;
                            padding: 8px 10px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            position: relative;
                            z-index: 2;
                            background-color: #f0f0f0;
                            &.message-info {
                                padding: 15px 10px 0 15px;
                                min-height: unset;
                                svg {
                                    margin-right: 5px;
                                }
                            }
                            .upload {
                                width: 100%;
                                .input-bt {
                                    display: flex;
                                    align-items: center;
                                    .input {
                                        width: 100%;
                                    }
                                }
                                .infos {
                                    display: flex;
                                    align-items: center;
                                    svg {
                                        margin-right: 5px;
                                    }
                                    p {
                                        margin: 0px;
                                    }
                                }
                            }
                        }
                        .removeMessage {
                            margin-left: 10px;
                            background-color: #ccc;
                        }
                        .emojis {
                            display: flex;
                            flex-direction: column;
                            .list {
                                visibility: hidden;
                                height: 0px;
                                overflow: auto;
                                width: 100%;
                                box-sizing: border-box;
                                transition: all 0.2s;
                                padding: 15px 20px;
                                margin-bottom: -40px;
                                &.active {
                                    height: 220px;
                                    visibility: visible;
                                    margin-bottom: 0px;
                                }
                                button {
                                    width: 32px;
                                    height: 32px;
                                    margin: 0px 6px;
                                    font-size: 28px;
                                    cursor: pointer;
                                }
                            }
                            .buttons {
                                margin-right: 10px;
                                display: flex;
                                align-items: center;
                                overflow: hidden;
                                button {
                                    margin-left: 10px;
                                    width: 26px;
                                    height: 26px;
                                    display: flex;
                                    cursor: pointer;
                                    align-items: center;
                                    background-color: transparent;
                                    border: none;
                                    color: #919191;
                                }
                                .close {
                                    margin-left: -30px;
                                    visibility: hidden;
                                    opacity: 0;
                                    transition: all 0.2s;
                                }
                                &.active {
                                    .open {
                                        path {
                                            fill: #009688;
                                        }
                                    }
                                    .close {
                                        margin-left: 10px;
                                        visibility: visible;
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                        .input {
                            min-height: 42px;
                            max-height: 100px;
                            overflow: auto;
                            flex: 1;
                            margin: 8px 0px;
                            padding: 9px 12px 11px;
                            box-sizing: border-box;
                            border-radius: 21px;
                            background-color: #fff;
                            box-sizing: border-box;
                            position: relative;
                            #input-send {
                                word-break: break-word;
                                white-space: pre-line;
                                outline: none;
                                position: relative;
                                z-index: 2;
                            }
                            span {
                                position: absolute;
                                top: 9px;
                                color: rgba(#000, 0.3);
                            }
                        }
                    }
                }
            }
        }
    }
    .el-full {
        cursor: move;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
