#contact-detail {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(#000, 0.8);
    z-index: 999999;
    opacity: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    .modal-contact-detail {
        background-color: #fff;
        width: 1000px;
        max-width: 90%;
        box-sizing: border-box;
        padding: 30px;
        border-radius: 10px;
        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h1 {
                margin: 0px;
                padding: 0px;
            }
            span {
                cursor: pointer;
            }
            svg {
                font-size: 30px;
            }
        }
        .infos {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            max-height: 80vh;
            overflow: auto;
            .photo {
                width: 300px;
                margin-right: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .image {
                    width: 200px;
                    height: 200px;
                    background-color: #cccc;
                    border-radius: 100px;
                    margin-bottom: 20px;
                    background-size: cover;
                }
            }
            .form {
                flex: 1;
                .row {
                    display: flex;
                    justify-content: space-between;
                }
            }
            h3 {
                font-weight: 400;
                border-top: 1px solid rgba(#000, 0.1);
                margin: 0px;
                padding-top: 10px;
                margin-top: 10px;
            }
        }
    }
}
