@import "../../Styles/Mixin.scss";

.tox-tinymce-aux {
    position: relative;
    z-index: 9999999999 !important;
    .tox-menu {
        z-index: 9999999999999 !important;
    }
}
#template-email {
    margin: 0px 0px;
    overflow: hidden;
    .translate {
        width: 200%;
        transition: all .5s;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .templates-html {
        height: 50px !important;
        ul {
            display: flex;
            align-items: center;
            li {
                padding: 10px 20px;
                margin: 0px 5px;
                background-color: rgba($color_text, .1);
                border-radius: 8px;
                white-space: nowrap;
                cursor: pointer;
                font-weight: 600;
            }
        }
    }
    .preview-box {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        .back {
            button {
                i {
                    font-size: 20px;
                    margin-right: 15px;
                }
            }
        }
        .separate-back {
            flex: 1;
            display: flex;
            justify-content: center;
        }
        .modal {
            display: flex;
            flex-direction: column;
            transition: all .2s;
            .navegador {
                display: flex;
                background-color: #F1F2F3;
                border-bottom: 1px solid rgba($color: #000000, $alpha: .05);
                height: 40px;
                align-items: center;
                .controls {
                    padding-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                        margin: 0px 5px;
                        width: 10px;
                        height: 10px;
                        border-radius: 100px;
                    }
                }
                .arrows {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    i {
                        margin: 0px 2px;
                        color: #5A5B5C;
                    }
                }
                .search {
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    .input-text {
                        display: flex;
                        background-color: #E2E3E4;
                        border-radius: 4px;
                        padding: 2px 10px;
                        align-items: center;
                        color: #999;
                        margin-left: -100px;
                        i {
                            font-size: 11px;
                            margin-right: 10px;
                        }
                        span {
                            font-size: 11px;
                        }
                    }
                }
            }
            &.phone {
                background: url(../../Images/mobile_frame.png) no-repeat;
                background-size: contain;
                width: 368px;
                height: 688px;
                display: flex;
                justify-content: center;
                box-sizing: border-box;
                padding: 38px 21px 38px 15px;
                .display {
                    overflow: auto;
                    width: 100%;
                    height: 100%;
                }
            }
            &.laptop {
                background: url(../../Images/notebook_frame.png) center no-repeat;
                background-size: contain;
                width: 1010px;
                height: 700px;
                display: flex;
                justify-content: center;
                box-sizing: border-box;
                padding: 94px 132px 129px 132px;
                .display {
                    overflow: auto;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .send-test {
        border-left: 1px solid rgba($color: #000000, $alpha: .1);
        flex: 1;
        display: flex;
        box-sizing: border-box;
        padding:0px 20px;
        align-items: center;
        margin: 20px 0px 0px 20px;
        label {

        }
    }
    .preview {
        justify-content: flex-start;
        padding-left: 40px;
        text-align: center;
        > div {
            display: flex;
            button {
                margin: 0px 5px;
                svg {
                    height: 30px;
                    width: 20px;
                    g, path {
                        fill: #FFF;
                    }
                }
            }
        }
    }
    .laptop-frame {
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        overflow: hidden;
        .shortcode {
            display: flex;
            justify-content: center !important;
            align-items: center !important;
            flex: 1;
            margin-bottom: 20px;
        }
        .title-shortcode {
            font-size: 17px;
            color: #606a75;
        }
        .display {
            min-width: 600px;
            width: 85%;
            height: 80vh;
            padding: 3% 1.5%;
            background-color: #616161;
            box-sizing: border-box;
            border-radius: 25px 25px 0px 0px;
            position: relative;
            > div {
                background-color: #FFF;
                position: relative;
                z-index: 99999999;
                height: 100% !important;
                overflow: hidden !important;
                > div {
                    height: calc(100% + 19px) !important;
                }
            }
            &:before {
                width: .6vw;
                height: .6vw;
                background-color: #FFF;
                border-radius: 100px;
                position: absolute;
                top: 2.5%;
                left: calc(50% - .7vw);
                content: ''
            }
            > div {
                width: 100%;
                height: 100%;
                background-color: #FFF;
                overflow: auto;
            }
        }
        .keyboard {
            width: 100%;
            img {
                width: 100%;
                position: relative;
            }
        }
    }
}