@import '../../Styles/Mixin.scss';

#dashboard {
    .list-reports {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 0px;
        margin-bottom: 20px;
    }
    .reports-graph {
        width: calc(100% - 286px - 40px);
        float: left;
        .filter {
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 25px;
            > .date {
                p {
                    font-size: 17px;
                    color: $color_text;
                    line-height: 18px;
                }
                > div {
                    width: 100%;
                    float: left;
                    label {
                        width: 35%;
                        float: left;
                        position: relative;
                        input {
                            font-size: 12px;
                            color: $color_text;
                            float: left;
                            box-sizing: border-box;
                            padding-left: 13px;
                            box-sizing: border-box;
                        }
                        i {
                            width: 10px;
                            height: 11px;
                            position: absolute;
                            z-index: 5;
                            left: 0;
                            top:4px;
                            background-image: url(../../Images/icos/icon_calendar.png);
                        }
                    }
                    > button {
                        width: 30%;
                        float: left;
                        position: relative;
                        border-radius: 100px;
                        font-size: 11px;
                        font-weight: bold;
                        padding: 5px 0px;
                        background-color: $color_yellow;
                        color: $color_text;
                        margin-top: -10px;
                        cursor: pointer;
                        &:hover {
                            box-shadow: 0px 3px 10px rgba($color: #000000, $alpha: .1);
                        }
                        &:active {
                            background-color: #e4bc47;
                        }
                    }
                }
            }
            .toggle {
                display: flex;
                flex-direction: row;
                div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: 22px;
                    span {
                        font-size: 17px;
                        color: $color_text;
                        padding-right: 5px;
                    }
                    label {}
                }
            }
        }
        h3 {
            font-size: 30px;
            width: 100%;
            font-weight: 500;
            color:$color_text;
            margin:25px 0px;
            span {
                font-size: 15px;
            }
        }
        .graph-time {
            border-top: 1px solid rgba($color: #000000, $alpha: .1);
            margin-top: 20px;
            float: left;
            margin-bottom: 20px;
            width: 100%;
        }
        .graph-content {
            width: 100%;
            float: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .description {
                display: flex;
                flex-direction: column;
                width: 170px;
                div {
                    margin:20px 0px;
                    display: flex;
                    flex-direction: column;
                    padding-left: 8px;
                    b {
                        font-size: 28px;
                        color: $color_text;
                        line-height: 20px;
                        padding-right: 10px;
                    }
                    span {
                        font-size: 20px;
                        color: $color_text;
                        line-height: 17px;
                        padding-top: 10px;
                    }
                }
            }
            .graph-load {
                height: 261px;
                width: calc(100% - 170px);
            }
        }
    }
    .video-phone {
        width: 286px;
        height: 506px;
        background-image: url(../../Images/video-phone.png);
        float: right;
    }
}