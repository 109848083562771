@import '../../Styles/Mixin.scss';

#chat {
    display: flex;
    background-color: #f8f9fa;
    height: calc(780px);
    margin-top: 30px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.02);
    color: rgba(0, 0, 0, 0.8);

    .loading-general {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        > div {
            width: 42px;
            height: 42px;
            background-color: #fff;
            box-shadow: 0 1px 1px 0 rgba(#000, 0.06),
                0 2px 5px 0 rgba(#000, 0.2);
            border-radius: 100px;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
        }
    }
    .left {
        width: 500px;
        max-width: 35%;
        background: #fff;
        border-right: 1px solid #e5e5e6;
        #profile {
            background-color: #ededed;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 15px;
            box-sizing: border-box;

            p {
                margin: 0px 0px 5px 0px;
            }
        }

        #search {
            background-color: #f6f6f6;
            height: 49px;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 0px 10px;
            .input {
                background: #fff;
                height: 35px;
                border-radius: 100px;
                width: 100%;
                display: flex;
                align-items: center;
                i {
                    margin-left: 10px;
                    margin-right: 10px;
                }
                input {
                    flex: 1;
                }
            }
        }
        .tab-contacts {
            display: flex;
            border-bottom: 1px solid #f2f2f2;
            justify-content: space-between;
            li {
                box-sizing: border-box;
                padding: 10px 10px;
                white-space: nowrap;
                cursor: pointer;
                flex: 1;
                font-size: 11px;
                border-bottom: 2px solid transparent;
                display: flex;
                align-items: center;
                text-align: center;
                &.active {
                    background-color: rgba(#000, 0.02);
                    border-bottom: 2px solid $color_yellow;
                    font-weight: 600;
                }
            }
        }
        #contacts {
            height: calc(100% - 59px - 49px - 30px);
            display: flex;
            flex-direction: column;
            .contact {
                border-bottom: 1px solid #f2f2f2;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding: 10px 15px 10px 10px;
                flex: 1;
                width: 100%;
                &:hover {
                    background-color: #f5f5f5;
                    cursor: pointer;
                }
                &.active {
                    background-color: #ebebeb;
                }
                .person {
                    flex: 1;
                    max-width: 70%;
                    display: flex;
                    flex-direction: column;
                    b {
                        font-size: 14px;
                    }
                    .message {
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.6);
                        &.active {
                            font-weight: 600;
                            color: rgba(0, 0, 0, 0.8);
                        }
                        .message-render {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: 100%;

                            .center-message {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                svg {
                                    margin-right: 3px;
                                    margin-bottom: -1px;
                                    color: $color_blue;
                                }
                            }
                        }
                    }
                }

                .infos {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    .date {
                        text-align: right;
                        font-size: 9px;
                    }
                    .totalMessage {
                        background: $color_blue;
                        border-radius: 100px;
                        display: flex;
                        height: 19px;
                        font-size: 12px;
                        padding: 0px 6px;
                        box-sizing: border-box;
                        color: #fff;
                        margin-top: 2px;
                        &.hidden {
                            visibility: hidden;
                        }
                    }
                }
            }
        }
    }
    .right {
        flex: 1;
        height: 100%;
        #conversation {
            background-color: #dddbd1;
            display: flex;
            flex-direction: column;
            height: 100%;
            position: relative;
            .chatStatus {
                position: absolute;
                right: 0px;
                margin: 10px;
                button {
                    background: rgba(red, 0.5);
                    border-radius: 100px;
                    padding: 8px 15px;
                    cursor: pointer;
                    color: #fff;
                    cursor: pointer;
                    svg {
                        margin-right: 5px;
                        path {
                            fill: #fff;
                        }
                    }
                }
            }
            &:after {
                width: 100%;
                height: 100%;
                background-image: url(https://web.whatsapp.com/img/bg-chat-tile-light_04fcacde539c58cca6745483d4858c52.png);
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.04;
                z-index: 1;
            }
            .relative {
                position: relative;
                z-index: 2;
                height: 100%;
                display: flex;
                flex-direction: column;
                .header {
                    height: 59px;
                    display: flex;
                    background-color: #ededed;
                    border-bottom: 1px solid #e7e0d8;
                    align-items: center;
                    padding: 5px 0px;
                    span {
                        margin-left: 20px;
                        font-weight: 600;
                        font-size: 18px;
                        margin-right: 20px;
                    }
                    .transfer {
                        display: flex;
                        align-items: center;
                        background-color: rgba(255, 255, 255, 0.5);
                        padding: 7px 20px;
                        .css-b62m3t-container {
                            margin: 0px 10px;
                        }
                    }
                }
                .messages {
                    justify-content: flex-end;
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    overflow: auto;
                    .position-bottom {
                        min-height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        padding-bottom: 10px;
                        box-sizing: border-box;
                    }
                    .message {
                        padding: 3px 30px;
                        display: flex;
                        flex-direction: column;

                        &.hidden {
                            visibility: hidden;
                        }
                        &.sent {
                            align-items: flex-end;
                            .content {
                                background-color: #dcf8c6;
                            }
                        }
                        &.received {
                            align-items: flex-start;
                        }
                        .author {
                            background-color: $color_yellow;
                            white-space: nowrap;
                            font-size: 9px;
                            border-radius: 100px;
                            padding: 4px 8px;
                            margin-bottom: -5px;
                            position: relative;
                            box-shadow: 0 1px 1px 0 rgba(#000, 0.06),
                                0 2px 5px 0 rgba(#000, 0.2);
                            display: flex;
                            align-items: center;
                            .icon-bot {
                                font-size: 20px;
                                margin-right: 3px;
                                margin-top: -3px;
                            }
                        }
                        .content {
                            background-color: #fff;
                            max-width: 60%;
                            padding: 6px 7px 8px 7px;
                            border-radius: 7.5px;
                            box-shadow: 0 1px 0.5px rgba(#000, 0.13);
                            .link-interative {
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 5px;
                                img {
                                    width: 90px;
                                    height: 90px;
                                }
                                .text {
                                    flex: 1;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    padding-left: 10px;
                                    background-color: rgba(#000, 0.05);
                                    border-radius: 4px;
                                    cursor: pointer;
                                    span {
                                        color: rgba(#000, 0.8);
                                    }
                                    i {
                                        font-size: 10px;
                                        color: rgba(#000, 0.5);
                                        font-style: normal;
                                    }
                                    p {
                                        margin: 0px;
                                        font-size: 12px;
                                        color: rgba(#000, 0.8);
                                    }
                                }
                            }
                            .message-render {
                                font-size: 13px;
                                box-sizing: border-box;
                                word-break: break-word;
                                white-space: pre-line;
                                .contact {
                                    width: 300px;
                                    display: flex;
                                    flex-direction: column;
                                    .name {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        width: 100%;
                                        border-bottom: 1px solid
                                            rgba(#000, 0.05);
                                        padding: 10px 0px;
                                        svg {
                                            width: 49px;
                                            height: 49px;
                                            margin-right: 15px;
                                            margin-left: 10px;
                                        }
                                        b {
                                            font-size: 14px;
                                            font-weight: 600;
                                        }
                                    }
                                    .telefones {
                                        display: flex;
                                        label {
                                            margin-top: 4px;
                                            cursor: pointer;
                                            display: flex;
                                            align-items: center;
                                            padding: 4px;
                                            border-radius: 3px;
                                            input {
                                                background-color: transparent;
                                                margin-left: 4px;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                                .localizacao {
                                    width: 270px;
                                    height: 240px;
                                }
                                .figure {
                                    img {
                                        width: 125px;
                                        height: 125px;
                                    }
                                }
                                .archive {
                                    display: flex;
                                    align-items: center;
                                    color: #919191;
                                    background-color: rgba(0, 0, 0, 0.05);
                                    padding: 10px;
                                    border-radius: 4px;
                                    img {
                                        height: 30px;
                                    }
                                    span {
                                        margin: 0px 40px 0px 10px;
                                    }
                                    svg {
                                    }
                                }
                                .photo,
                                video {
                                    width: 300px;
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    height: 150px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    overflow: hidden;
                                    border-radius: 5px;
                                    margin-top: 4px;
                                    cursor: pointer;
                                    position: relative;
                                    &:after {
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        content: '';
                                        top: 0;
                                        left: 0;
                                        background-color: rgba(#000, 0.1);
                                        visibility: hidden;
                                        opacity: 0;
                                        transition: all 0.5s;
                                    }
                                    &:hover {
                                        &::after {
                                            visibility: visible;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                            .infos {
                                display: flex;
                                justify-content: flex-end;
                                margin-top: 5px;
                                margin-bottom: -5px;
                                .date {
                                    font-size: 9px;
                                    margin-right: 5px;
                                }
                                svg {
                                    width: 14px;
                                }
                            }
                        }
                    }
                }
                .button-scroll {
                    position: relative;
                    .scroll-to-bottom {
                        width: 42px;
                        height: 42px;
                        background-color: #fff;
                        box-shadow: 0 1px 1px 0 rgba(#000, 0.06),
                            0 2px 5px 0 rgba(#000, 0.2);
                        position: absolute;
                        top: -82px;
                        right: 0px;
                        margin: 20px;
                        border-radius: 100px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        justify-content: center;
                    }
                }
                .sent-message {
                    display: flex;
                    flex-direction: column;
                    background-color: #f0f0f0;
                    .preview-send {
                        z-index: 99;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        background-color: #ededed;
                        animation: fadeInUp 0.5s;
                        .head {
                            width: 100%;
                            height: 59px;
                            background-color: $color_blue;
                            display: flex;
                            align-items: center;
                            padding: 0px 10px;
                            box-sizing: border-box;
                            button {
                                margin-right: 10px;
                                padding: 0px;
                                margin: 0px;
                                display: flex;
                                background-color: transparent;
                                margin-right: 10px;
                                cursor: pointer;
                            }
                        }
                        .preview {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            flex: 1;
                            height: 100%;
                            img,
                            video,
                            audio {
                                max-width: 50%;
                                max-height: 40%;
                            }
                            span {
                                font-size: 12px;
                                margin-top: 10px;
                            }
                            button {
                                margin-top: 30px;
                            }
                        }
                    }
                    .visible {
                        min-height: 62px;
                        padding: 8px 10px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        position: relative;
                        z-index: 2;
                        background-color: #f0f0f0;
                    }
                    .emojis {
                        display: flex;
                        flex-direction: column;
                        .list {
                            visibility: hidden;
                            height: 0px;
                            overflow: auto;
                            width: 100%;
                            box-sizing: border-box;
                            transition: all 0.2s;
                            padding: 15px 20px;
                            margin-bottom: -40px;
                            &.active {
                                height: 220px;
                                visibility: visible;
                                margin-bottom: 0px;
                            }
                            button {
                                width: 32px;
                                height: 32px;
                                margin: 0px 6px;
                                font-size: 28px;
                                cursor: pointer;
                            }
                        }
                        .buttons {
                            margin-right: 10px;
                            display: flex;
                            align-items: center;
                            overflow: hidden;
                            button {
                                margin-left: 10px;
                                width: 26px;
                                height: 26px;
                                display: flex;
                                cursor: pointer;
                                align-items: center;
                                background-color: transparent;
                                border: none;
                                color: #919191;
                            }
                            .close {
                                margin-left: -30px;
                                visibility: hidden;
                                opacity: 0;
                                transition: all 0.2s;
                            }
                            &.active {
                                .open {
                                    path {
                                        fill: #009688;
                                    }
                                }
                                .close {
                                    margin-left: 10px;
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    .send-medias {
                        button.send-menu {
                            margin-left: 5px;
                            margin-right: 10px;
                            width: 26px;
                            height: 26px;
                            display: flex;
                            cursor: pointer;
                            align-items: center;
                            background-color: transparent;
                            border: none;
                            color: #919191;
                        }
                        &.active {
                            .submenu-media {
                                bottom: 80px;
                                opacity: 1;
                                visibility: visible;
                                button {
                                    animation: zoomIn 0.5s;
                                }
                            }
                        }
                        .submenu-media {
                            opacity: 0;
                            visibility: hidden;
                            transition: all 0.5s;
                            display: flex;
                            flex-direction: column;
                            position: absolute;
                            bottom: 50px;
                            button {
                                position: relative;
                                width: 43px;
                                height: 43px;
                                border-radius: 100px;
                                overflow: hidden;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: 0px 10px 10px rgba(#000, 0.1);
                                margin-bottom: 5px;
                                cursor: pointer;
                                span {
                                    width: 100%;
                                    height: 100%;
                                    margin: -10px 0px 0px -10px;
                                }
                                input {
                                    position: absolute;
                                    left: 0;
                                    opacity: 0;
                                    height: 100%;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .input {
                        min-height: 42px;
                        max-height: 100px;
                        overflow: auto;
                        flex: 1;
                        margin: 8px 0px;
                        padding: 9px 12px 11px;
                        box-sizing: border-box;
                        border-radius: 21px;
                        background-color: #fff;
                        box-sizing: border-box;
                        position: relative;
                        #input-send {
                            word-break: break-word;
                            white-space: pre-line;
                            outline: none;
                            position: relative;
                            z-index: 2;
                        }
                        span {
                            position: absolute;
                            top: 9px;
                            color: rgba(#000, 0.3);
                        }
                    }
                    .audio-record {
                        .start-record {
                            margin: 0px 10px;
                            cursor: pointer;
                            svg {
                                path {
                                    fill: #919191;
                                }
                            }
                        }
                        .recording-audio {
                            display: flex;
                            align-items: center;
                            margin-left: 20px;
                            button {
                                width: 35px;
                                height: 35px;
                                border: 1px solid rgba(#000, 0.1);
                                border-radius: 100px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                svg {
                                    width: 18px;
                                    height: 18px;
                                }
                                &.check {
                                    border: 1px solid rgba(rgb(12, 204, 12), 1);
                                    &:hover {
                                        background-color: rgba(
                                            rgb(12, 204, 12),
                                            1
                                        );
                                    }
                                }
                                &.close {
                                    border: 1px solid rgba(rgb(253, 90, 90), 1);
                                    &:hover {
                                        background-color: rgba(
                                            rgb(253, 90, 90),
                                            1
                                        );
                                    }
                                }
                            }
                            .seconds {
                                margin: 0px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .messageButton {
        padding: 8px 10px;
        border-radius: 8px;
        border: 1px solid rgba(#000, 0.1);
        margin: 0px 5px;
        background: rgba(#fff, 0.2);
        cursor: pointer;
    }
}
