@import "../../Styles/Mixin.scss";

.conversation-webchat {
    overflow: auto;
    height: calc(100% - 41px);
    .scroll-messages > div:first-child {
        padding: 20px;
        box-sizing: border-box;
    }
    .message {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        .content-message {
            animation: zoomIn .5s;
            border-radius: 100px;
            background-color: #FFF;
            padding: 8px 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .status {
                display: flex;
                margin-left: 10px;
                svg {
                    width: 14px;
                    path {
                        
                    }
                }
            }
        }
        &.sent {
            align-items: flex-end;
            .content-message {
                background-color: $color_blue_purple;
                color: #FFF;
                border-radius: 20px 20px 0px 20px;
            }
        }
        &.received {
            align-items: flex-start;
            .content-message {
                background-color: #FFF;
                color: #555;
                border-radius: 20px 20px 20px 0px;
            }
        }
        &.search {
        
        }
        span {
            font-size: 12px;
        }
        .image, .video {
            width: 100px;
            height: 100px;
            border-radius: 15px;
            margin: 8px 0px;
            background-color: rgba(#000, .1);
            background-size: cover;
            cursor: pointer;
        }
        .video {
            width: 200px;
            height: 150px;
        }
        .file {
            display: flex;
            background: #FFF;
            border-radius: 10px;
            align-items: center;
            padding: 10px;
            margin: 8px 0px;
            span {
                margin: 0px 10px;
            }
            img {
                height: 30px;
            }
        }
        i {
            font-size: 8px;
            margin-left: 5px;
            font-style: normal;
            animation: fadeInDown .5s;
        }
    }
    .rating {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        color: #666;
        align-items: flex-start;
        .title {
            font-weight: 300;
            font-size: 15px;
            b {
                font-weight: 600;
            }
        }
        .question {
            background: #FFF;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 8px;
            width: 200px;
            max-width: 100%;
            p {
                font-size: 13px;
                margin: 0px 0px 5px 0px;
            }
            .options {
                display: flex;
                label {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    cursor: pointer;
                    input {
                        margin-right: 5px;
                    }
                }
            }
            .stars {
                i {
                    font-size: 20px;
                    margin-right: 5px;
                    cursor: pointer;
                }
            }
            .obs {
                textarea {
                    width: 100%;
                }
            }
        }
    }
}