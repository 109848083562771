

#monitoramento {
    @import "~bootstrap/scss/bootstrap";
    box-sizing: border-box;
    padding: 40px;
    .danger {
        background-color: #f8d7da;
    }
    .success {
        background-color: #d4edda;
    }
    .selected {
        background: #bcdefb;
    }
    .filters {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        button {
            margin-right: 20px;
        }
    }
    th {
        font-weight: 600;
        white-space: nowrap;
    }
    td {
        white-space: nowrap;
    }
    .item {
        background-color: rgba($color: #000000, $alpha: .03);
        margin-right: 20px;
        &.row {
            margin-right: 0px !important;
        }
        h3 {
            margin-top: 10px;
        }
        &:last-child {
            margin-right: 0px;
        }
    }
    header {
        margin-top: 0px;
        padding-top: 0px;
        margin-bottom: 25px;
    }
}