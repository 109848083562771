@import '../../Styles/Mixin.scss';
.process-load {
    width: 100%;
    height: 45px;
    border: 1px solid rgba($color: $color_text, $alpha: 0.1);
    border-radius: 0px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    border-radius: 5px;
    &::after {
        content: 'Aguarde, processando arquivo.';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(#000, $alpha: 0.5);
    }
    > div {
        height: 100%;
        background: linear-gradient(to right, $color_blue_purple, $color_blue);
        transition: all 1s linear;
        width: 0px;
    }
}
.process-load-contacts {
    width: 100%;
    height: 45px;
    border: 1px solid rgba($color: $color_text, $alpha: 0.1);
    border-radius: 0px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    border-radius: 5px;
    &::after {
        content: 'Aguarde, processando contatos.';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgba(#000, $alpha: 0.5);
    }
    > div {
        height: 100%;
        background: linear-gradient(to right, $color_blue_purple, $color_blue);
        transition: all 1s linear;
        width: 0px;
    }
}
.modal-contact {
    width: 100vw;
    position: fixed;
    z-index: 100000;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 100vh;
    box-sizing: border-box;
    padding: 40px 0px;
    .message-clean {
        background-color: rgba(yellow, 0.1);
        border-radius: 5px;
        border: 1px solid rgba(#000, 0.3);
        padding: 10px;
        display: flex;
        align-items: center;
        svg {
            margin-right: 5px;
        }
    }
    &.closed {
        .bg-close {
            animation-name: fadeOut !important;
            animation-duration: 1s;
        }
        .modal {
            animation-name: backOutUp !important;
            animation-duration: 1s;
        }
    }
    .bg-close {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba($color: $color_text, $alpha: 0.9);
        z-index: 1;
        animation-name: fadeIn;
        animation-duration: 0.5s;
        cursor: pointer;
        border: none;
    }
    .modal {
        top: 100px;
        background-color: #fff;
        width: 800px;
        z-index: 2;
        border-radius: 5px;
        animation-name: backInDown;
        animation-duration: 0.5s;
        position: relative;
        .close-modal {
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            margin: -20px;
            cursor: pointer;
            background-color: rgba(255, 192, 203, 0.9);
        }
        .head {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            background-color: rgba($color: $color_text, $alpha: 0.05);
            button {
                white-space: nowrap;
                margin-left: 10px;
                padding: 0px 15px;
                border-radius: 4px;
                font-size: 13px;
                background-color: $color_yellow;
                color: $color_text;
                cursor: pointer;
                font-weight: 600;
                &:hover {
                    opacity: 0.8;
                }
                &:active {
                    opacity: 0.5;
                }
            }
        }
        .content {
            padding: 0px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            max-width: 800px;
            .overflow {
                max-height: 58vh;
                overflow: auto;
            }
            .tfoot {
                padding: 20px;
                display: flex;
                justify-content: flex-end;
            }

            table {
                border-radius: 0px;
                tbody {
                    max-height: 70vh;
                    overflow: auto;
                }
                th,
                td {
                    white-space: nowrap;
                    .select-column {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        select {
                            flex: 1;
                            margin: 0px;
                        }
                    }
                }
                .input-default-zig {
                    margin: 0px;
                    input {
                        margin: 0px !important;
                        &::placeholder {
                            color: rgba($color: $color_text, $alpha: 0.3);
                        }
                    }
                }
            }
        }
        .button {
            display: flex;
            padding: 20px;
            justify-content: flex-start;
            background-color: rgba($color: $color_text, $alpha: 0.1);
            button {
                margin-right: 15px;
            }
        }
        .process-load {
            width: 100%;
            height: 45px;
            border: 1px solid rgba($color: $color_text, $alpha: 0.1);
            border-radius: 0px;
            overflow: hidden;
            position: relative;
            box-sizing: border-box;
            border-radius: 5px;
            &::after {
                content: 'Aguarde, processando arquivo.';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: rgba(#000, $alpha: 0.5);
            }
            > div {
                height: 100%;
                background: linear-gradient(
                    to right,
                    $color_blue_purple,
                    $color_blue
                );
                transition: all 1s linear;
                width: 0px;
            }
        }
    }
    .button-submit {
        padding: 15px;
        button {
            display: inline-block;
            padding: 10px 25px;
            border-radius: 100px;
            font-weight: 600;
            cursor: pointer;
            &.submit {
                background-color: $color_yellow;
                color: $color_text;
            }
            &.new {
                background-color: $color_text;
                color: #fff;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
#higienizacao {
    .tfoot {
        padding: 20px;
        display: flex;
        justify-content: flex-end;
    }
    .process-load {
        width: 100%;
        height: 45px;
        border: 1px solid rgba($color: $color_text, $alpha: 0.1);
        border-radius: 0px;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        border-radius: 5px;
        margin-top: 20px;
        &::after {
            content: 'Aguarde, processando arquivo.';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: rgba(#000, $alpha: 0.5);
        }
        > div {
            height: 100%;
            background: linear-gradient(
                to right,
                $color_blue_purple,
                $color_blue
            );
            transition: all 1s linear;
            width: 0px;
        }
    }
    .message-clean {
        background-color: rgba(yellow, 0.1);
        border-radius: 5px;
        border: 1px solid rgba(#000, 0.3);
        padding: 10px;
        display: flex;
        align-items: center;
        svg {
            margin-right: 5px;
        }
    }
}

#higienizacao-contatos {
    padding-top: 10px;
    .button-send {
    }
}

.button-edit-list {
    display: inline-block;
    width: unset !important;
    &.full {
        width: 100% !important;
        text-align: center;
        align-items: center;
        display: flex;
    }
}
