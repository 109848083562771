@import '../../Styles/Mixin.scss';

#login {
    background-color: #4d5158;
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .form {
        width: 730px;
        height: 480px;
        border-radius: 15px;
        background-color: #FFF;
        display: flex;
        flex-wrap: row;
        animation-name: backInDown;
        animation-duration: 1s;
        overflow: hidden;
        .left {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .top {
                padding: 35px 0px 35px 65px;
                img {}
                p {
                    font-size: 16px;
                    color: $color_text;
                    padding-top: 15px;
                }
            }
            form {
                padding: 0px 45px;
                label {
                    position: relative;
                    margin-bottom: 20px;
                    width: 100%;
                    float: left;
                    input {
                        width: 100%;
                        height: 45px;
                        border: 1px solid $color_text;
                        padding: 0 30px 0px 14px;
                        box-sizing: border-box;
                    }
                    i {
                        width: 29px;
                        height: 45px;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        background-position: center left;
                        background-repeat: no-repeat;
                        &.email {
                            background-image: url(../../Images/icos/icon_email.png);
                        }
                        &.password {
                            background-image: url(../../Images/icos/icon_password.png);
                        }
                    }
                }
                > a {
                    font-size: 14px;
                    color: $color_blue;
                    padding-left: 14px;
                    box-sizing: border-box;
                }
                button {
                    width: 154px;
                    height: 44px;
                    border-radius: 100px;
                    margin-top: 15px;
                    background-color: $color_yellow;
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: #606a75;
                    cursor: pointer;
                }
            }
        }
        .right {
            background-image: url(../../Images/banner_login.jpg);
            height: 100%;
            width: 50%;
            border-radius: 0px 13px 13px 0px;
        }
    }
}