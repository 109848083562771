@import '../../Styles/Mixin.scss';

#analytics {
    .status-check {
        margin: 5px 0px -4px 7px;
        display: inline-block;
    }
    .tfoot {
        margin: 20px 0px;
    }
    .toggle {
        display: flex;
        margin-top: 17px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: .1);
        padding-bottom: 20px;
        > div {
            background-color: rgba($color: #000000, $alpha: .06);
            border-radius: 5px;
            padding: 10px;
            margin-right: 10px;
            cursor: pointer;
            > span {
                margin-right: 8px;
            }
        }
    }
    .row-sintetic {
        display: flex;
        align-items: center;
        width: calc(100% + 24px);
        height: 205px;
        margin: 20px -24px;
        .column-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        .column {
            height: 100%;
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            margin: 0px 12px;
            .row {
                display: flex;
                justify-content: space-between;
                height: calc(50% - 12px);
            }
            .info {
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.06);
                box-sizing: border-box;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
                border-radius: 5px;
                width: 100%;
                height: 100%;
                margin: 0px 12px;
                padding: 18px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .label {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    span {
                        font-size: 13px;
                        text-transform: uppercase;
                        color: rgba(#000, .5);
                    }   
                    b {
                        font-size: 15px;
                        color: rgba(#000, .7);
                    }
                    i {
                        font-size: 11px;
                        svg {
                            width: 7.5px;
                            height: 7.5px;
                        }
                        &.down {
                            svg {
                                transform: rotate(90deg);
                                margin-right: 4px;
                                path {
                                    fill: red;
                                }
                                color: red;
                            }
                        }
                    }
                }
                > svg {
                    width: 20px;
                    height: 20px;
                    margin-left: 30px;
                }
            }
        }
    }
}

