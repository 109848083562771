@import '../../Styles/Mixin.scss';

header {
    width: 100%;
    float: left;
    box-sizing: border-box;
    margin: 27px 0px 0px 0px;
    border-bottom: 1px solid #8899a1;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    .left {
        color: $color_text;
        .name-client {
            font-size: 22px;
            font-weight: 700;
        }
        .name-user {
            font-size: 17px;
            line-height: 15px;
            padding-bottom: 4px;
        }
    }
    .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .notification {
            height: 24px;
            margin-right: 27px;
            button {
                width: 23px;
                height: 24px;
                background-image: url(../../Images/icos/icon_notification.png);
                background-color: #fff;
                position: relative;
                cursor: pointer;
                span {
                    background-color: $color_yellow;
                    border-radius: 100px;
                    font-size: 8px;
                    padding: 1px 4px;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    border: 2px solid #fff;
                }
            }
        }
        .menu-user {
            padding: 10px;
            position: relative;
            transition: all 0.2s;
            min-width: 115px;
            button {
                display: flex;
                align-items: center;
                background-color: transparent;
                position: relative;
                z-index: 2;
                cursor: pointer;
                i {
                    width: 34px;
                    height: 34px;
                    border-radius: 9px;
                    margin-right: 10px;
                    background-size: cover;
                    background: url(../../Images/profile-default.png) center;
                    background-size: cover;
                }
                &:after {
                    width: 7px;
                    height: 5px;
                    background-image: url(../../Images/icos/icon_arrow_down.png);
                    margin-left: 10px;
                    content: '';
                    transition: all 0.2s;
                }
            }
            .submenu {
                z-index: 999999;
                width: 100%;
                background-color: $color_text;
                position: absolute;
                top: 0px;
                left: 0px;
                display: flex;
                flex-direction: column;
                padding: 55px 10px 10px 10px;
                border-radius: 8px;
                opacity: 0;
                visibility: hidden;
                transition: all 0.2s;
                box-sizing: border-box;
                a {
                    color: #fff;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 3px;
                    margin-bottom: 3px;
                    white-space: nowrap;
                    &:hover {
                        font-weight: 600;
                    }
                }
            }
            &:hover {
                .submenu {
                    z-index: 999999;
                    opacity: 1;
                    visibility: visible;
                    animation-name: zoomIn;
                    animation-duration: 0.2s;
                }
                button {
                    z-index: 9999991;
                }
            }
        }
    }
}
.nav-left {
    width: 240px;
    background-image: linear-gradient(to bottom, #5973f7, #44a8ff);
    border-radius: 30px;
    float: left;
    overflow: hidden;
    margin-bottom: 25px;
    position: relative;
    z-index: 99999;
    .logo {
        width: 100%;
        padding: 26px 0px 0px 0px;
        text-align: center;
        float: left;
        img {
            display: inline-block;
        }
        &:after {
            width: 140px;
            height: 1px;
            background: #499dff;
            display: inline-block;
            content: '';
        }
    }
    > .link {
        width: 100%;
        padding: 5px 22px;
        display: inline-block;
        box-sizing: border-box;
        a {
            background-color: $color_yellow;
            width: 100%;
            height: 45px;
            box-sizing: border-box;
            padding: 5px 0px;
            display: flex;
            border-radius: 100px;
            text-align: center;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            justify-content: center;
            align-items: center;
            vertical-align: middle;
            color: $color_text;
            transition: all 0.2s;
            overflow: hidden;
            span {
                transition: all 0.2s;
            }
            b {
                font-size: 1.6rem;
                height: 19px;
                font-weight: 1000;
                margin-right: 6px;
                transition: all 0.5s;
                line-height: 16px;
            }
            &:hover,
            &.active {
                box-shadow: 0px 2px 5px rgba($color: #000000, $alpha: 0.2);
                b {
                    transform: rotate(180deg);
                    line-height: 14px;
                }
            }
            &.active {
                background-color: #fff;
                b {
                    transform: rotate(90deg);
                }
            }
        }
    }
    ul {
        width: 100%;
        display: inline-block;
        padding-left: 34px;
        box-sizing: border-box;
        li {
            > span {
                padding: 5px 15px;
                display: inline-block;
                font-size: 10px;
                text-transform: uppercase;
                font-weight: bold;
                color: #adc2fc;
            }
            > a {
                height: 44px;
                width: 100%;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                margin: 5px 0px;
                position: relative;
                transition: all 0.2s;
                img {
                    height: auto;
                    margin: 0px 17px;
                    position: relative;
                    z-index: 2;
                }
                svg {
                    margin: 0px 17px;
                    width: 24px;
                    height: 30px;
                    position: relative;
                    z-index: 2;
                    path {
                        fill: #23ccfe;
                    }
                }
                i {
                    display: inline-block;
                    margin: 0px 15px;
                    display: inline-block;
                    position: relative;
                    z-index: 2;
                    color: #2acdfe;
                    height: 25px;
                    svg {
                        font-size: 25px;
                    }
                }
                span.text {
                    color: #fff;
                    font-size: 15px;
                    position: relative;
                    z-index: 2;
                    transition: all 0.5s;
                }
                &:before,
                &:after {
                    transition: all 0.2s;
                    transition-delay: 0.2s;
                }
                &:before {
                    width: 19px;
                    height: 22px;
                    content: '';
                    position: absolute;
                    top: -22px;
                    right: -19px;
                    background-image: url(../../Images/curve_menu_top.png);
                }
                &:after {
                    width: 19px;
                    height: 22px;
                    content: '';
                    position: absolute;
                    bottom: -22px;
                    right: -19px;
                    background-image: url(../../Images/curve_menu_bottom.png);
                }
                .bg {
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    border-radius: 100px 0px 0px 100px;
                    z-index: 1;
                    position: absolute;
                    right: -100%;
                    transition: all 0.5s;
                }
                &.active {
                    span {
                        color: $color_text;
                    }
                    &:before,
                    &:after {
                        right: 0px;
                    }
                    .bg {
                        right: 0px;
                    }
                }
            }
        }
    }
    .suport {
        width: 100%;
        box-sizing: border-box;
        padding: 25px;
        a {
            height: 222px;
            background-color: rgba($color: #fff, $alpha: 0.2);
            border-radius: 15px;
            position: relative;
            background-image: url(../../Images/dash_support.png);
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            display: inline-block;
            padding: 10px 15px;
            box-sizing: border-box;
            transition: all 0.2s;
            span {
                display: block;
                transition: all 0.2s;
            }
            .title {
                font-size: 16px;
                color: #fff;
                font-weight: 400;
            }
            .description {
                font-size: 10px;
                color: #fff;
                font-weight: 600;
            }
            .link {
                font-size: 12px;
                color: $color_text;
                background-color: $color_yellow;
                border-radius: 100px;
                padding: 3px 20px;
                display: inline-block;
                margin-top: 15px;
                font-weight: 600;
            }
            img {
                position: absolute;
                right: 0px;
                bottom: 0px;
                transition: all 0.2s;
            }
            &.active {
                .link {
                    opacity: 0;
                }
            }
            &:hover,
            &.active {
                background-color: rgba($color: #000000, $alpha: 0.05);
                .link {
                    margin-left: 90px;
                }
                img {
                    -moz-transform: scaleX(-1);
                    -o-transform: scaleX(-1);
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                    right: 72px;
                }
            }
        }
    }
}
.section-private {
    width: calc(100% - 240px - 37px);
    float: right;
    margin-left: 27px;
    > .content {
        box-sizing: border-box;
        float: left;
        width: 100%;
        position: relative;
        z-index: 99999;
        .page {
            animation: pageReload;
            animation-duration: 0.3s;
        }
        h1 {
            font-size: 30px;
            color: $color_text;
            font-weight: 300;
            padding: 28px 0px;
            margin: 0px;
            display: flex;
            line-height: 35px;
            button {
                font-size: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color_yellow;
                cursor: pointer;
                margin-right: 10px;
                border-radius: 12px;
                &:hover {
                    background-color: rgba($color_yellow, $alpha: 0.8);
                }
                i {
                    margin: 0px 14px 0px 10px;
                }
                span {
                    font-size: 11px;
                    display: none;
                }
                &:hover {
                    span {
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
            }
            b,
            input {
                font-weight: 600;
                display: flex;
                flex-direction: column;
                color: $color_text;
                width: 100%;
                font-size: 30px;
                height: 35px;
                background-color: rgba($color: #000000, $alpha: 0.05);
                &:after {
                    content: '';
                    width: 100%;
                    height: 4px;
                    background-color: $color_yellow;
                }
            }
            input {
                &:hover {
                    background-color: rgba($color: #000000, $alpha: 0.1);
                }
            }
        }
        .h1-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

@keyframes pageReload {
    from {
        filter: blur(100px);
    }
    to {
        filter: blur(0px);
    }
}
