@import "../../Styles/Mixin.scss";

#webchat-operator {
    display: flex;
    margin-top: 30px;
    height: 780px;
    border: 1px solid rgba(#000, .1);
    border-radius: 5px;
    position: relative;
    .messages-conversation {
        height: 100%;
    }
    .users {
        width: 400px;
        max-width: 35%;
        background: #FFF;
        border-right: 1px solid #e5e5e6;
        box-sizing: border-box;
        .search {
            display: flex;
            height: 35px;
            justify-content: space-between;
            border-bottom: 1px solid rgba(#000, .05);
            padding: 10px 10px 10px 10px;
            background: #f6f6f6;
            input {
                flex: 1;
                padding: 0px 10px;
                border-radius: 100px;
                background: #FFF;
                font-size: 12px;
                color: #666;
            }
            button {
                cursor: pointer;
                background: transparent;
                padding: 0px 20px;
                transition: all .2s;
                &:hover {
                    color: $color_blue_purple;
                    transform: scale(1.3);
                }
            }
        }
        .scroll-users {
            width: 100%;
            height: calc(100% - 93px);
            position: relative;
            .all-user {
                padding-top: 15px;
            }
            .load-users {
                width: 100%;
                height: 0%;
                overflow: hidden;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 99;
                font-size: 50px;
                transition: all .2s;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                &.active {
                    height: 100%;
                }
            }
            h5 {
                margin: 0px;
                font-weight: 200;
                padding: 10px 0px 0px 10px;
                box-sizing: border-box;
            }
            .search-user {
                padding-top: 15px;
                margin-bottom: 40px;
                .user {
                    margin-bottom: -36px;
                    box-shadow: 0px -5px 10px rgba(#000, .1);
                }
            }
        }
        .filters {
            margin-bottom: 0px;
            display: flex;
            justify-content: space-between;
            padding: 0px 0px 0px 0px;
            border-bottom: 1px solid #f2f2f2;
            button {
                box-sizing: border-box;
                padding: 10px 10px;
                white-space: nowrap;
                cursor: pointer;
                font-size: 11px;
                border-bottom: 2px solid transparent;
                display: flex;
                align-items: center;
                background: #FFF;
                &.active {
                    background-color: rgba(#000, .02);
                    border-bottom: 2px solid $color_yellow;
                    font-weight: 600;
                }
                i {
                    position: absolute;
                    top: -9px;
                    right: -9px;
                    margin: 0px;
                    padding: 3px 6px;
                    background: $color_yellow;
                    display: none;
                    border-radius: 100px;
                    font-style: normal;
                    font-size: 8px;
                    color: #000;
                }
            }
        }
        .user {
            display: flex;
            align-items: center;
            width: calc(100% - 20px);
            margin: 0px 10px;
            justify-content: space-between;
            background-color: #f2f2f2;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 10px;
            margin-bottom: 5px;
            cursor: pointer;
            position: relative;
            transition: all .2s;
            &.active {
                background-color: $color_blue_purple;
                color: #FFF;
            }
            .profile {
                flex: 1;
                display: flex;
                flex-direction: column;
            }
            .infos {
                margin-left: 10px;
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: flex-end;
                select {
                    background: #FFF;
                    text-align: right;
                    border-radius: 5px;
                    margin-top: 25px;
                    font-size: 12px;
                    padding: 4px;
                }
            }
            .name {
                font-size: 14px;
                font-weight: 400;
            }
            .last {
                font-size: 11px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 220px;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 5px;
                }
            }
            .status {
                display: flex;
                
                align-items: flex-start;
                span {
                    font-size: 11px;
                    padding: 5px 8px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    margin-top: 4px;
                    margin-right: 4px;
                    color: rgba(#000, .7);
                    &.operator {
                        background: #e6e6e6;
                    }
                    &.danger {
                        background: rgba(243, 230, 230, 1);
                    }
                    &.warning {
                        background: #f3ead9;
                    }
                    svg {
                        margin-right: 5px;
                    }
                }
            }
            .date {
                font-size: 9px;
                white-space: nowrap;
            }
            .total {
                background-color: $color_yellow;
                position: absolute;
                right: 0px;
                top: 0px;
                color: #000;
                font-size: 8px;
                padding: 3px 7px;
                border-radius: 100px;
                margin-top: 4px;
                margin-right: 4px;
                animation: zoomIn .5s;
            }
        }
    }
    #chat-web-t2u {
        height: 100%;
        box-sizing: border-box;
        .embed-head {
            width: 100%;
            background: #FFF;
            border-bottom: 1px solid rgba(#000, .1);
            padding: 10px;
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            .open-embed {
                cursor: pointer;
                background: transparent;
                cursor: pointer;
            }
        }
    }
    #embed {
        label {
            margin-left: 0px !important;
        }
        .embed-final {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            #openChat {
                display: flex !important;
                width: 50px;
                height: 50px;
                min-width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 100px;
                margin-top: 10px;
                box-shadow: 0px 5px 5px rgba(0,0,0,.1);
                margin-left: 20px;
                svg {
                    height: 20px;
                    width: 30px;
                }
            }
            textarea {
                width: 100%;
                height: 80px;
                background: rgba(#000, .1);
                border-radius: 8px;
                padding: 10px;
                cursor: pointer;
            }
        }
    }
}