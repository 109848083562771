.input-mask-tropa {
    position: relative;
    width: 400px;
    height: 30px;
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;
        font-size: 13px;
        font-family: 'Arial';
    }
}