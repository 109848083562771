@import '../../Styles/Mixin.scss';

#usuario {
    .nav-config {
        box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: .1);
        display: inline-block;
        margin-bottom: 25px;
        margin-top: 15px;
        button {
            color: $color_text;
            font-size: 18px;
            height: 45px;
            padding: 0px 80px;
            background-color: #FFF;
            cursor: pointer;
            &.active {
                background-color: $color_blue_purple;
                color: #FFF;
            }
        }
    }
    .row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .change-photo {
        color: $color_text;
        .change {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin-left: 100px;
            span {
                font-size: 17px;
            }
            > div {
                width: 100px;
                height: 100px;
                background-size: cover;
                background-position: center;
                margin: 10px 0px;
                border-radius: 10px;
            }
            label {
                input {
                    display: none;
                }
                .upload {
                    display: flex;
                    align-items: center;
                    height: 34px;
                    box-sizing: border-box;
                    padding:10px 15px;
                    background-color: #606a75;
                    border-radius: 100px;
                    font-weight: 600;
                    font-size: 12px;
                    color: #FFF;
                    cursor: pointer;
                    white-space: nowrap;
                    margin-top: 2px;
                    &:hover {
                        opacity: .8;
                    }
                    &:active {
                        opacity: 1;
                    }
                    i {
                        background: url(../../Images/icos/upload_icon.png) center no-repeat;
                        width: 20px;
                        height: 17px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .credit-card {
        display: flex;
        .form {
            width: 100%;
        }
        .card {
            margin-left: 40px;
        }
    }
}