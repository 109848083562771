@import '../../Styles/Mixin.scss';

#administracao {
    margin-bottom: 20px;
    height: 100%;
    .tfoot {
        margin: 20px 0px;
    }
    h3 {
        font-size: 20px;
        margin-bottom: 0px;
        font-weight: 300;
        color: rgba($color: #000000, $alpha: .5);
    }
    .perfil-user {
        display: flex;
        align-items: center;
        margin-top: 2px;
        .photo-list {
            width: 35px;
            height: 35px;
            margin-right: 10px;
            border-radius: 5px;
            background-size: cover;
            background-color: #eaeaea;
        }
        span {

        }
    }
    .brokers {
        > div {
            display: inline-block;
            align-items: center;
            background-color: rgba($color: #000000, $alpha: .1);
            margin: 5px 5px 0px 0px;
            border-radius: 4px;
            padding: 10px 15px;
            cursor: pointer;
            &.active {
                background-color: $color_blue;
                span {
                    color: #FFF;
                }
            }
            > div {
                display: flex;
                align-items: center;
                input {
                    margin-right: 10px;
                }
                span {
                    font-size: 13px;
                }
            }
        }
    }
    .change-photo {
        display: flex;
        align-items: center;
        .image {
            margin-right: 20px;
            width: 100px;
            height: 100px;
            border-radius: 5px;
            background-color: #EAEAEA;
            background-position: center;
            background-size: cover;
        }
    }
}

#logs-box {
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 1);
    display: flex;
    flex-direction: column;
    height: 100vh;
    .title {
        padding: 10px 20px;
        box-sizing: border-box;
        background-color: #ccc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
            display: flex;
            align-items: center;
            button {
                margin-right: 10px;
            }
        }
        select {
            height: 100%;
            padding: 0px 10px;
            border-radius: 5px;
        }
    }
    .logs {
        overflow: auto;
        flex: 1;
        .message {
            border-bottom: 1px solid rgba(#FFF, .2);
            padding: 20px;
            color: $color_blue;
            animation-name: fadeIn;
            animation-duration: 1s;
            ul {
                list-style:decimal;
                margin: 0px 18px;
                li {
                    color: $color_yellow;
                }
            }
            .info {
                font-size: 12px;
            }
        }
    }
    .button {
        padding: 10px;
        button {

        }
    }
}